<template>
	<div data-view>
		<!-- Main -->
		<section class="full-screen top-section">
			<div class="container">
				<div class="row">

					<div class="col-lg-6 col-md-7 col-12 d-flex align-items-center">
						<div class="about-text">
							<h1 class="animated animated-text" style="animation-name: fadeInLeft;animation-delay: 0s;animation-duration: 1.8s;animation-iteration-count: 1;">
								<span class="mr-2">{{ $t('wlc_1') }}</span>
								<div class="animated-info">
									{{ $t('wlc_2') }} <span class="animated-item text-primary">{{ $t('wlc_3') }}</span>
								</div>
							</h1>
							<p class="lead text-center text-md-left text-muted mr-3" style="animation-name: fadeInRight;animation-delay: 0s;animation-duration: 1.8s;animation-iteration-count: 1;">
								{{ $t('wlc_4') }}
							</p>
							<div class="custom-btn-group mt-4 mb-4">
								<router-link :to="{name: 'contactus'}" class="mr-2 custom-btn custom-btn-primary">{{ $t('wlc_5') }}</router-link>
								<router-link :to="{name: 'register'}" class="custom-btn custom-btn-light">
									{{ $t('wlc_6') }} <i class="fa fa-chevron-right fa-size-1"></i>
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-5 col-12">
						<div class="about-image svg"><!-- text-center -->
							<img src="@/assets/HomePage/img/main-illustration.png" class="img-fluid" alt="Sender Lead">
						</div>
					</div>
					
				</div>
			</div>
		</section>
		
		<!-- ------------------------------------------------------------------- -->
		
		<section class="section-content-feature">
			<div class="container">
				<div class="row justify-content-center mb-4 text-center">
					<div class="col-lg-8"><!-- bottom-hr -->
						<h2 class="h1 strong-600">{{ $t('wlc_7') }}</h2>
						<p class="text-muted">{{ $t('wlc_8') }}</p>
						<hr class="hr-feature" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<div class="left-icon-wraper">
							<div class="icon"><i class="flaticon-email"></i></div>
							<div class="content">
								<!--h2>Brand Visibility</h2-->
								<h2>{{ $t('wlc_9') }}</h2>
								<p>{{ $t('wlc_10') }}</p>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="left-icon-wraper">
							<div class="icon"><i class="flaticon-viral-marketing"></i></div>
							<div class="content">
								<h2>{{ $t('wlc_11') }}</h2>
								<p>{{ $t('wlc_12') }}</p>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="left-icon-wraper">
							<div class="icon">
								<i class="flaticon-blogging"></i>
							</div>
							<div class="content">
								<h2>{{ $t('wlc_13') }}</h2>
								<p>{{ $t('wlc_14') }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<div class="left-icon-wraper">
							<div class="icon"><i class="flaticon-design"></i></div>
							<div class="content">
								<h2>{{ $t('wlc_15') }}</h2>
								<p>{{ $t('wlc_16') }}</p>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="left-icon-wraper">
							<div class="icon"><i class="flaticon-ranking"></i></div>
							<div class="content">
								<h2>{{ $t('wlc_17') }}</h2>
								<p>{{ $t('wlc_18') }}</p>
							</div>
							
						</div>
					</div>
					<div class="col-md-4">
						<div class="left-icon-wraper">
							<div class="icon"><i class="flaticon-targeting"></i></div>
							<div class="content">
								<h2>{{ $t('wlc_19') }}</h2>
								<p>{{ $t('wlc_20') }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- .container -->
		</section>
		<!-- /.services-left-icon -->

		<!-- ------------------------------------------------------------------- -->
		<div class="container-fluid complement-info bg-white">
			<div class="row justify-content-center mb-4 text-center padding-title">
				<div class="col-lg-12"><!-- bottom-hr -->
					<h2 class="h1 strong-600">{{ $t('wlc_21') }}</h2>
					<p class="text-muted">{{ $t('wlc_22') }}</p>
					<hr class="hr-feature" />
				</div>
			</div>
			
			<section class="about_us section_padding">
				<div class="container">
					<div class="row align-items-center justify-content-between">
						<div class="col-12 col-md-6 col-lg-6">
							<div class="about_us_text">
								<h2 v-html="$t('wlc_23')"></h2>
								<p>{{ $t('wlc_24') }}</p>
								<!--a href="#" class="btn_2">learn more</a-->
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-6">
							<div class="learning_img d-flex">
								<img src="@/assets/HomePage/img/animate_icon/Ellipse_4.png" alt="" class="feature_icon_1 custom-animation1" style="position: absolute;left: 0px;top: 0px;" />
								<img src="@/assets/HomePage/img/about_img.png" :alt="$t('wlc_23')" class="m-auto" />
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<!--::about_us part start::-->
			<section class="about_us right_time mt-5">
				<div class="container pb-4">
					<div class="row align-items-center justify-content-between">
						<div class="col-12 col-md-6 col-lg-6">
							<div class="learning_img">
								<img src="@/assets/HomePage/img/about_img_1.png" alt="" />
								<img src="@/assets/HomePage/img/animate_icon/Ellipse_5.png" alt="" class="feature_icon_2 custom-animation2" style="position: absolute;right: 30px;top: 0px;" />
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-6">
							<div class="about_us_text">
								<h2>{{ $t('wlc_25') }}</h2>
								<p>{{ $t('wlc_26') }}</p>
								<!--a href="#" class="btn_2">learn more</a-->
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		
		<section class="slice slice-lg pb-5 pt-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 order-lg-2 ml-lg-auto pl-lg-5">
						<!-- Heading -->
						<h5 class="h2 mt-4">{{ $t('wlc_27') }}</h5>
						<!-- Text -->
						<p class="lead lh-190 my-4">
							{{ $t('wlc_28') }}
						</p>
						<!-- List -->
						<ul class="list-unstyled">
							<li class="py-2">
								<div class="d-flex align-items-center">
									<div>
										<div class="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
											<i class="ti-home"></i>
										</div>
									</div>
									<div>
										<span class="h6 mb-0">{{ $t('wlc_29') }}</span>
									</div>
								</div>
							</li>
							<li class="py-2">
								<div class="d-flex align-items-center">
									<div>
										<div class="icon icon-shape icon-warning icon-sm rounded-circle mr-3">
											<i class="ti-palette"></i>
										</div>
									</div>
									<div>
										<span class="h6 mb-0">{{ $t('wlc_30') }}</span>
									</div>
								</div>
							</li>
							<li class="py-2">
								<div class="d-flex align-items-center">
									<div>
										<div class="icon icon-shape icon-success icon-sm rounded-circle mr-3">
											<i class="ti-settings"></i>
										</div>
									</div>
									<div>
										<span class="h6 mb-0">{{ $t('wlc_31') }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="col-lg-6 order-lg-1">
						<!-- Image -->
						<div class="position-relative zindex-100">
							<img alt="Image placeholder" src="@/assets/HomePage/img/illustration-2.svg" class="img-fluid">
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="pt-5 bg-white text-center">
			<h2 class="h1">{{ $t('wlc_32') }}</h2>
			<h4 class="mt-3">{{ $t('wlc_33') }}</h4>
			<router-link :to="{ name: 'register' }" class="custom-btn custom-btn-primary mt-4">{{ $t('wlc_34') }} <i class="fa fa-chevron-right fa-size-1"></i></router-link>
		</section>
		
	</div>
</template>
<script>
	export default {
		name: 'Home',
		methods: {
			TokenCheck() {
				
				let that 	= this
				let url  	= '/email/check'
				let method  = 'POST'
				let token  	= that.$route.params.token
				let data 	= { token: token }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					if( response.data.status == 'success' ) {
						that.$router.push({name: 'dashboard'})
					}
				}).catch(() => {
					
				} )
				
			}
		},
		mounted(){
			let that = this
			if( that.$route.name == 'welcome' && typeof( that.$route.params.token ) != 'undefined' ) {
				that.TokenCheck()
			}
		}
	};
</script>
<style lang="scss">
	.animated-text {
		font-size: 3rem;
		font-weight: 700;
		line-height: 1.3;
	}
	.top-section {
		background-color: #fff;
	}
	.full-screen {
		padding: 5rem 0 2rem 0; /* 6rem */
		
		.about-image {
			width: 90%;
			margin: auto;
		}
		
	}
	
	.padding-title {
		padding: 4rem 0 2rem 0;
	}
	
	.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
		color: #152c5b;
	}
	
	.fa-size-1 {
		font-size:12px;
		vertical-align: inherit;
	}
	
	.section-content-feature {
		padding: 4rem 0 3rem 0;
		.content, .icon{
			display: table-cell;
			vertical-align: top;
		}
		.content{
			padding-left: 20px;
			h2{
				font-size: 18px;
			}
		}
		.icon {
			width: 70px;
			i{
				width: 70px;
				height: 55px;
				text-align: center;
				border-right: 2px solid #008aff;
				/* border-radius: 25px; */
				display: block;
				
				&::before {
					font-size: 35px;
					margin: 0;
					padding: 0;
					color: #008aff; /* #4d6de3 */
				}
			}
		}
	}
	.bottom-hr:after {
		position: absolute;
		content: "";
		bottom: 0;
		left: 45%;
		width: 100px;
		height: 2px;
		background: #008aff;
		margin-bottom: 6px;
	}
	
	.hr-feature {
		width: 110px;
		border: 1px solid #2196f3;
		margin: 0 auto;
	}
</style>
