<template>
	
	<div class="background"><!-- container mt-5 mb-5 -->
		<!--h4 class="c-grey-900 mT-10 mB-30">New Request</h4-->
		<div class="container-fluid pt-5 pb-5">
			<div class="row">
				<div class="col-12 text-center pricing-description">
					<div class="pricing-title">{{ $t('pricing_1') }}</div><!-- Choose your Pricing Plan -->
					<p>{{ $t('pricing_2') }}</p>
					<p>{{ $t('pricing_3') }}</p>
				</div>
			</div>
			<div class="row panel pricing-table" v-if="ListPlans.length == 0">
				<!--vue-content-loading :width="300" :height="100">
					<circle cx="30" cy="30" r="30" />
					<rect x="75" y="13" rx="4" ry="4" width="100" height="15" />
					<rect x="75" y="37" rx="4" ry="4" width="50" height="10" />
				</vue-content-loading-->
				<!--vue-content-loading :height="50">
					<rect x="140" y="10" rx="4" ry="4" width="110" height="30" />
				</vue-content-loading-->
				<div class="col-12 col-sm-12 col-md-6 col-lg-3" v-for="(loader) in 4" v-bind:key="`loader-${loader}`">
					<div class="pricing-plan">
						<div class="text-center">
							<vue-content-loading :height="180"><circle cx="190" cy="90" r="70" /></vue-content-loading>
						</div>
						<div class="border-bottom pt-4">
							<vue-content-loading :height="50">
								<rect x="0" y="17" rx="4" ry="4" width="200" height="10" />
							</vue-content-loading>
						</div>
						<ul class="pricing-features m-0">
							<li class="border-bottom" v-for="(key) in 4" v-bind:key="`f-${key}`">
								<vue-content-loading :height="70">
									<rect x="0" y="30" rx="4" ry="4" width="370" height="10" />
								</vue-content-loading>
							</li>
						</ul>
						<div class="row">
							<div class="col-4 my-auto"></div>
							<div class="col-8 text-right">
								<vue-content-loading :height="100" :width="300">
									<rect x="30" y="10" rx="4" ry="4" width="270" height="80" />
								</vue-content-loading>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row panel pricing-table" v-else>
				<div class="col-12 col-sm-12 col-md-6 col-lg-3" v-for="(plan, id) in ListPlans" v-bind:key="`plan-${id}`">
					<div class="pricing-plan">
						<div class="text-center">
							<img :src="LoadImage(plan.name)" :alt="plan.name" class="pricing-img" />
						</div>
						<h2 class="pricing-header">{{ TitlePlans[plan.name] }}</h2>
						<b class="feature-title">{{ FeatureTitle[plan.name] }}</b>
						<ul class="pricing-features">
							<li class="pricing-features-item" v-for="(feature, key) in plan.features" v-bind:key="`feature-${key}`">{{ feature }}</li>
						</ul>
						<div class="row">
							<div class="col-4 my-auto"><span class="pricing-price">${{ plan.price }}</span>/Mo</div>
							<div class="col-8 text-right">
								<button class="custom-btn custom-btn-primary" @click="$router.push('register')">Get started <i class="fa fa-chevron-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	
</template>
<script>
	import { VueContentLoading } from "@/components";
	
	export default {
		name: 'Pricing',
		data() {
			return {
				ListPlans	: [],
				TitlePlans	: { 'starter': 'Starter', 'basic': 'Basic', 'business': 'Business', 'enterprise': 'Rapid Growth' },
				FeatureTitle: {
					'starter'	: 'Includes:',
					'basic'		: 'Everything in STARTER and more:',
					'business'	: 'Everything in BASIC and more:',
					'enterprise': 'Everything in BUSINESS and more:'
				},
			}
		},
		methods: {
			GetPlans() {
				let that = this
				
				let url  	= '/plans/get'
				let method  = 'GET'
				let data 	= {}
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					
					let feedback	 = response.data
					feedback.plans.forEach( function(plan) {
						that.ListPlans.push(plan)
					} )
					
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
				} )
			},
			LoadImage(image) {
				return require('@/assets/HomePage/img/Pricing/' + image + '.png')
			}
		},
		mounted() {
			this.GetPlans()
		},
		components: {
			//
			VueContentLoading,
			
		}
	}
</script>
<style lang="scss">
	.pricing-description {
		z-index: 10;
		color: #ffffff; /* 212121 */
		margin-bottom: 20px;
		.pricing-title {
			font-weight: bold;
			font-size: 48px;
			line-height: 57px;
			margin-bottom: 10px;
		}
		p {
			font-size: 16px;
			margin-bottom: 0px;
		}
	}
	.background {
		position: relative;
		width: 100%;
	}
	
	.background::after {
		content: '';
		background: #60a9ff;
		background: -moz-linear-gradient(top, #60a9ff 0%, #4394f4 100%);
		background: -webkit-linear-gradient(top, #60a9ff 0%,#4394f4 100%);
		background: linear-gradient(to bottom, #60a9ff 0%,#4394f4 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60a9ff', endColorstr='#4394f4',GradientType=0 );
		height: 435px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
	
	.panel {
		position: relative;
		z-index: 10;
	}
	
	.feature-title {
		font-size: 13px;
		margin-bottom: 5px;
		display: block;
	}
	
	.pricing-plan {
		border-bottom: 1px solid #e1f1ff;
		padding: 25px;
		background-color: #fff;
		border-radius: 10px;
		/* box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02); */
		box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08), 0px 1px 6px 3px rgba(0, 0, 0, 0.09), 0px 1px 1px 1px rgba(0, 0, 0, 0.02);
		margin-bottom: 15px;
		&:hover {
			background-color: #f9fdff;
		}
	}
	
	@media (min-width: 900px) {
		.pricing-plan {
			border-bottom: none;
			border-right: 1px solid #e1f1ff;
			flex-basis: 100%;
			padding: 25px 25px;
		}
	}
	
	.pricing-img {
		margin-bottom: 5px;
		max-width: 100%;
		width: 10em;
	}
	
	.pricing-header {
		font-weight: 600;
		letter-spacing: 1px;
		text-align: center;
		margin-bottom: 25px;
	}
	
	.pricing-features {
		margin-bottom: 25px;
		list-style: none;
		padding: 0px;
		min-height: 15em;
		
		.pricing-features-item {
			border-top: 1px solid #e1f1ff;
			padding: 14px 0;
			
			color: #545454;
			font-size: 13px;
			line-height: 22px;
			
		}
		.pricing-features-item:before {
			content: '✓';
			padding-right: 10px;
			color: #2196f3;
		}
	}
	
	.pricing-features-item:last-child {
		border-bottom: 1px solid #e1f1ff;
	}
	
	.pricing-price {
		color: #016FF9;
		font-size: 24px;
	}
</style>