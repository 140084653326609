<template>
	
	<div class="container mt-5 mb-5">
		<!--h4 class="c-grey-900 mT-10 mB-30">New Request</h4-->
		<div class="row">
			<div class="col-md-12 label-weight">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<h2 class="mB-20">{{ $t('termservice_1') }}</h2><!-- c-grey-900 -->
					
					<div class="row">
						<div class="col-md-12 text-justify">
							
							<h5>{{ $t('termservice_2') }}</h5>
							<p>{{ $t('termservice_3') }}</p>
							
							
							<h4>{{ $t('termservice_4') }}</h4>
							<p>{{ $t('termservice_5') }}</p>
							
							<h4>{{ $t('termservice_6') }}</h4>
							<p>{{ $t('termservice_7') }}</p>
							<p>{{ $t('termservice_8') }}</p>
							
							<h4>{{ $t('termservice_9') }}</h4>
							<p>{{ $t('termservice_10')[0] }} <a href="/privacy-policy" target="_blank">{{ $t('termservice_10')[1] }}</a> {{ $t('termservice_10')[2] }}</p>
							
							<h4>{{ $t('termservice_11') }}</h4>
							<p>{{ $t('termservice_12') }}</p>
							
							<h4>{{ $t('termservice_13') }}</h4>
							<p>{{ $t('termservice_14') }}</p>
							<p v-html="$t('termservice_15')"></p>
							
							<h4>{{ $t('termservice_16') }}</h4>
							<p>{{ $t('termservice_17') }}</p>
							
							<div>
								<hr><small v-html="$t('termservice_18')"></small>
							</div>
							
						</div>
						
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	
	export default {
		name: 'TermsOfService',
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		mounted() {
			
		},
		components: {
			
		}
	}
</script>
<style lang="scss">
	
</style>