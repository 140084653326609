<template>
	
	<div class="container mt-5 mb-5">
		<!--h4 class="c-grey-900 mT-10 mB-30">New Request</h4-->
		<div class="row">
			<div class="col-md-12 label-weight">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<h4 class="c-grey-900 mB-20">{{ $t('cntus_1') }}</h4>
					<p>{{ $t('cntus_2') }}</p>
					<div class="row">
						<div class="col-md-8">
							<form autocomplete="off" @submit.prevent="submitRequest" method="post">
							
								<div class="row">
									<div class="form-group col-md-6">
										<label for="contactEmail">{{ $t('cntus_3') }} <b class="text-danger" title="required field">*</b></label>
										
										<input type="text" :class="['form-control', validateArray.email != '' ? 'is-invalid' : '']" id="contactEmail" :placeholder="$t('cntus_4')" v-model="requestData.email" required />
										<small class="form-text text-muted">{{ $t('cntus_4') }}</small>
									</div>
									
									<div class="form-group col-md-6">
										<label for="Department">{{ $t('cntus_5') }}<b class="text-danger" title="required field">*</b></label>
										<select id="Department" v-model="requestData.department" :class="['form-control', validateArray.department != '' ? 'is-invalid' : '']" required>
											<option disabled> {{ $t('cntus_6')[0] }} </option>
											<option value="GeneralQuestion">{{ $t('cntus_6')[1] }}</option>
											<option value="TechnicalSupport">{{ $t('cntus_6')[2] }}</option>
											<option value="PaymentBilling">{{ $t('cntus_6')[3] }}</option>
											<option value="AccountDetails">{{ $t('cntus_6')[4] }}</option>
											<option value="Renewal">{{ $t('cntus_6')[5] }}</option>
										</select>
										<small class="form-text text-muted">{{ $t('cntus_7') }}</small>
									</div>
								</div>
								
								<!--div class="row">
									<div class="form-group col-md-12">
										<label for="attachment">Attachment (Optional)</label>
										<input type="file" class="form-control" id="attachment" style="height: 42px;" required />
										<small class="form-text text-muted">Accept : Images, PDF, CSV, TXT, ZIP Maximum size: 10.00MB</small>
									</div>
								</div-->
								
								<div class="row">
									<div class="form-group col-md-12">
										<label for="subject">{{ $t('cntus_8')[0] }}<b class="text-danger" title="required field">*</b></label>
										<input type="text" id="subject" v-model="requestData.subject" :placeholder="$t('cntus_8')[1]" :class="['form-control', validateArray.subject != '' ? 'is-invalid' : '']" required />
									</div>
								</div>
								
								<div class="row">
									<div class="form-group col-md-12">
										<label for="message">{{ $t('cntus_9')[0] }}<b class="text-danger" title="required field">*</b></label>
										<textarea id="message" :placeholder="$t('cntus_9')[1]" v-model="requestData.message" rows="6" :class="['form-control', validateArray.message != '' ? 'is-invalid' : '']" required></textarea>
									</div>
								</div>
								
								<div class="alert alert-success" v-if="success">
									<p class="m-0">{{ $t('cntus_10') }}</p>
								</div>
								<div class="row">
									<div class="form-group col-md-12 text-right">
										<button class="btn btn-info" type="submit">
											<i :class="['fa', loading ? 'fa-circle-o-notch fa-spin' : 'fa-send']"></i> {{ $t('cntus_11') }}
										</button>
									</div>
								</div>
								<div>
									<hr><small>{{ $t('cntus_12') }}</small>
								</div>
								
							</form>
						</div>
						<div class="col-md-4">
							<h4>{{ $t('cntus_13') }}</h4>
							<p>{{ $t('cntus_14') }}</p>
							<p>{{ $t('cntus_15') }}</p>
							<p>{{ $t('cntus_16') }}</p>
							<p>{{ $t('cntus_17') }}</p>
							<div style="font-size: 13px;">
								<div class="row pb-2">
									<div class="col-5 pr-0"><i class="fa fa-envelope-o"></i> <b>{{ $t('cntus_18') }}</b></div>
									<div class="col-7 text-secondary">contact@senderlead.com</div>
								</div>
								<div class="row">
									<div class="col-5 pr-0"><i class="ti-headphone-alt"></i> <b>{{ $t('cntus_19') }}</b></div>
									<div class="col-7 text-secondary">+1 603 3331385</div>
								</div>
								<!--div class="row pB-3">
									<div class="col-5"><b>Payment requests :</b></div>
									<div class="col-7 text-secondary">payment@senderlead.com</div>
								</div-->
							</div>
						</div>
						
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	
	export default {
		name: 'ContactUs',
		data() {
			return {
				loading		: false,
				requestData	: {
					email		: '',
					department	: 'GeneralQuestion',
					attachment 	: '',
					subject  	: '',
					message   	: ''
				},
				validateArray: { email: '', department: '', subject: '', message: '' },
				success		: false
				
			}
		},
		methods: {
			submitRequest() {
				
				let that = this
				let validateArray= that.validateArray
				Object.keys(validateArray).forEach(function(field) {
					that.validateArray[field] = ''
				})
				
				that.success = false
				that.loading = true
				let url  	 = '/visitor/submit_request'
				let method   = 'POST'
				
				let data 	= { email: that.requestData.email, department: that.requestData.department, subject: that.requestData.subject, message: that.requestData.message }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					if(response.data.message == 'success' ) {
						// that.$router.push({path: 'help'})
						
						that.requestData.email		= ''
						that.requestData.department	= 'GeneralQuestion'
						that.requestData.attachment	= ''
						that.requestData.subject  	= ''
						that.requestData.message   	= ''
					}
					that.success = true
					that.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.loading = false
				} )
				
			}
		},
		mounted() {
			// let that = this
			// that.$store.state.selectedPage = that.$route.name
			// that.requestData.email = that.$store.state.user.email
			// that.$store.state.loading = false
		},
		components: {
			//
		}
	}
</script>
<style lang="scss">
	
</style>