<template>
	
	<div class="container mt-5 mb-5">
		<!--h4 class="c-grey-900 mT-10 mB-30">New Request</h4-->
		<div class="row">
			<div class="col-md-12 label-weight">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<h2 class="mB-20">{{ $t('privacyp_1') }}</h2><!-- c-grey-900 -->
					
					<div class="row">
						<div class="col-md-12 text-justify">
							
							<h4>{{ $t('privacyp_2') }}</h4>
							<p>{{ $t('privacyp_3') }}</p>
							<p>{{ $t('privacyp_4') }}</p>
							
							<h4>{{ $t('privacyp_5') }}</h4>
							<p>{{ $t('privacyp_6') }}</p>
							
							<h4>{{ $t('privacyp_7') }}</h4>
							<p>{{ $t('privacyp_8') }}</p>
							
							<p><b>{{ $t('privacyp_9')[0] }}</b> {{ $t('privacyp_9')[1] }}</p>
							
							<div>
								<hr><small>{{ $t('privacyp_10') }} <b>contact@senderlead.com</b></small>
							</div>
							
						</div>
						
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	
	export default {
		name: 'PrivacyPolicy',
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		mounted() {
			
		},
		components: {
			//
		}
	}
</script>
<style lang="scss">
	
</style>